import { useFloating, useInteractions, useDismiss } from '@floating-ui/react'
import { animated, useSpring } from '@react-spring/web'

import { WoodwingContent } from '/features/Woodwing'
import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Credits.css'
import cameraIcon from '/images/icons/camera.raw.svg'

export function Credits({ credits, description = undefined, layoutClassName = undefined }) {
  return (
    <CreditsPhotoIconOverlay
      {...{ layoutClassName }}
      renderContent={() =>
        <CreditsContent layoutClassName={styles.contentLayout} {...{ credits, description }} />
      }
    />
  )
}

export function CreditsPhotoIconOverlay({ renderContent, layoutClassName = undefined }) {
  const [isOpen, setIsOpen] = React.useState(false)

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: open => {
      setIsOpen(open)
    }
  })

  const dismiss = useDismiss(context, { capture: false })
  const { getFloatingProps } = useInteractions([ dismiss ])

  return (
    <div className={cx(styles.componentPhotoIconOverlay, layoutClassName, !isOpen && styles.isClosed)}>
      <Overlay
        overlayRef={refs.setFloating}
        {...{ renderContent }}
        floatingProps={getFloatingProps()}
        isVisible={isOpen}
        layoutClassName={styles.overlayLayout}
      />
      <CameraButton
        buttonRef={refs.setReference}
        onClick={() => setIsOpen(x => !x)}
        layoutClassName={styles.cameraButtonLayout}
      />
    </div>
  )
}

function Overlay({ overlayRef, renderContent, isVisible, layoutClassName, floatingProps }) {
  const revealSpring = useSpring({
    opacity: isVisible ? 1 : 0,
    config: { mass: 1, tension: 280, friction: 35 }
  })

  return (
    <animated.div
      className={cx(styles.componentOverlay, layoutClassName, isVisible && styles.isActive)}
      style={revealSpring}
      {...floatingProps}
      ref={overlayRef}
    >
      {renderContent({ layoutClassName: styles.contentLayout })}
    </animated.div>
  )
}

function CreditsContent({ credits, description, layoutClassName }) {
  return (
    <div className={cx(styles.componentContent, layoutClassName)}>
      <WoodwingContent contentItems={credits || []} />
      <WoodwingContent contentItems={description || []} />
    </div>
  )
}

function CameraButton({ onClick, layoutClassName, buttonRef }) {
  return (
    <button
      ref={buttonRef}
      type='button'
      data-x='toggle-credits'
      className={cx(styles.componentCameraButton, layoutClassName)}
      {...{ onClick }}
    >
      <Icon icon={cameraIcon} layoutClassName={styles.iconLayout} />
    </button>
  )
}
