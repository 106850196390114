import { animated } from '@react-spring/web'
import { useQueryString } from '@kaliber/sanity-routing/queryString'

import styles from './MetaTag.css'

export function MetaTag({ label }) {
  return (
    <div className={styles.component}>
      <span>{label}</span>
    </div>
  )
}

export function MetaTagButton({ label, onActiveChange }) {
  const [_, setQueryString] = useQueryString()

  return (
    <button
      type='button'
      data-x='click-to-open-tag-related-articles'
      onClick={handleClick}
      className={styles.componentButton}
    >
      <span>{label}</span>
    </button>
  )

  function handleClick() {
    onActiveChange(true)
    setQueryString(x => ({ ...x, tag: label }))
  }
}

export function MetaTagWrapper({ children, animation = undefined }) {
  return (
    <animated.div style={animation} className={styles.componentWrapper}>
      {children}
    </animated.div>
  )
}
