import { useFloating, useDismiss, useInteractions, FloatingOverlay as FloatingOverlayBase } from '@floating-ui/react'
import { useSpring, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { useQuery } from '@tanstack/react-query'
import { useElementSize } from '@kaliber/use-element-size'
import { useQueryString } from '@kaliber/sanity-routing/queryString'

import { useLanguage, useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'
import { useDebouncedValue } from '/machinery/useDebounced'
import { useSearchContext } from '/machinery/SearchContext'
import { ensureArray } from '/machinery/ensure'
import { pushToDataLayer } from '/machinery/tracking/pushToDataLayer'

import { Icon } from '/features/buildingBlocks/Icon'
import { ImageCover } from '/features/buildingBlocks/Image'
import { Logo } from '/features/regionArticles/Logo'
import { InputText } from '/features/buildingBlocks/Input'
import { TagButton } from '/features/buildingBlocks/TagButton'
import { DrawerArticleLinkSearch } from '/features/regionArticles/deckToc/DrawerArticleLink'
import { LoaderCircleGray } from '/features/pageOnly/Loader'
import { getEsEditionTags, getEsSearchResultWithinAndNotWithinRegion } from '/features/search/requests'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Search.css'

import arrowRight from '/images/icons/arrow-right.raw.svg'
import searchIcon from '/images/icons/search.raw.svg'
import markIcon from '/images/icons/mark.raw.svg'

export function Search({ isOpen, onClose, coverImage, layoutClassName = undefined }) {
  const { refs, getFloatingProps } = useFloatingProps({ isOpen, onClose })

  return (
    <div aria-hidden={!isOpen} className={cx(styles.component, layoutClassName)}>
      <FloatingOverlayBase lockScroll={isOpen} className={cx(styles.floatingOverlay, isOpen && styles.overlayActive)}>
        <SearchDrawer
          floatingProps={getFloatingProps()}
          layoutClassName={styles.drawerLayout}
          {...{ isOpen, refs, onClose, coverImage }}
        />
      </FloatingOverlayBase>
    </div>
  )
}

function SearchDrawer({ isOpen, floatingProps, onClose, coverImage, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  const { style, elementRef } = useDrawerStyle({ isOpen })

  const [{ tags: rawTags = [] }] = useQueryString()
  const tags = ensureArray(rawTags)

  const editionTags = useTags()
  const { search, results, status } = useArticles()
  const { withinRegion, notWithinRegion } = results
  const { isFiltering, isFetching } = status

  const noArticlesFound = search.value.length && !withinRegion.articles?.length && !notWithinRegion.articles?.length && !isFetching

  const bind = useDrag(
    ({ swipe: [swipeX], movement: [movementX] }) => {
      if (swipeX === 1 || movementX > 150) {
        onClose()
      }
    }, { axis: 'x' }
  )

  return (
    <animated.div
      ref={elementRef}
      className={cx(styles.componentDrawer, layoutClassName)}
      {...bind()}
      {...{ style }}
      {...floatingProps}
    >
      <SearchHeader search={search.value} onSearchChange={handleSearchChange} layoutClassName={styles.headerLayout} {...{ onClose, coverImage }} />

      {tags.length > 0 && <SelectedSuggestion activeTags={tags.map(x => editionTags.find(tag => tag.value === x)).filter(Boolean)} />}

      {noArticlesFound && (
        <div className={styles.drawerContainer}>
          <SearchHeading text={`${__`no-search-results`}:`} />
        </div>
      )}

      {isFetching && (
        <div className={styles.drawerContainer}>
          <LoaderCircleGray layoutClassName={styles.loaderLayout} />
        </div>
      )}

      {!isFiltering && <SearchSuggestions tags={editionTags} />}

      <ul className={styles.resultsContainer}>
        {withinRegion?.articles?.map((item, i) => (
          <DrawerArticleLinkSearch key={i} {...{ item }} />
        ))}
        {Boolean(notWithinRegion.total) && <h3 className={styles.differentRegionsHeading}>{__`from-different-regions`}</h3>}
        {notWithinRegion?.articles?.map((item, i) => (
          <DrawerArticleLinkSearch key={i} {...{ item }} />
        ))}
      </ul>
    </animated.div>
  )

  function handleSearchChange(value) {
    search.setValue(value)
  }
}

function SearchHeader({ search, onSearchChange, onClose, coverImage, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <header className={cx(styles.componentHeader, layoutClassName)}>
      <div className={styles.imageContainer}>
        <ImageCover
          image={coverImage}
          aspectRatio={isViewportMd ? 16 / 9 : 9 / 16}
          layoutClassName={styles.imageLayout}
        />
      </div>

      <div className={styles.headerWrapper}>
        <div className={styles.headerContentContainer}>
          <button onClick={onClose} data-x='click-to-close-search' className={styles.backButton}>
            <Icon icon={arrowRight} layoutClassName={styles.iconLayout} />
          </button>

          <Logo layoutClassName={styles.logoLayout} />

          <SearchForm layoutClassName={styles.formLayout} {...{ search, onSearchChange }} />
        </div>
      </div>
    </header>
  )
}

function SearchForm({ search, onSearchChange, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const inputRef = React.useRef(null)

  return (
    <div className={cx(styles.componentForm, layoutClassName)}>
      <Icon icon={searchIcon} layoutClassName={styles.iconLayout} />

      <div ref={inputRef} className={styles.inputContainer}>
        <InputText
          hasIcon
          placeholder={`${__`i-am-searching-for`}...`}
          maxLength={64}
          value={search}
          onChange={handleChange}
          layoutClassName={styles.inputLayout}
        />
      </div>

      {Boolean(search.length) && (
        <button onClick={handleDeletePrompt} className={styles.deletePromptButton}>
          <Icon icon={markIcon} layoutClassName={styles.iconLayout} />
        </button>
      )}
    </div>
  )

  function handleChange(e) {
    onSearchChange(e.currentTarget.value)
  }

  function handleDeletePrompt(e) {
    const input = inputRef.current?.childNodes[0]

    onSearchChange('')
    input.focus()
  }
}

function SearchSuggestions({ tags, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentSuggestions, layoutClassName)}>
      <SearchHeading text={`${__`suggestions`}:`} />

      <ul className={styles.suggestionsList}>
        {tags.slice(0, 5).map((item, i) => (
          <Suggestion key={i} {...{ item }} />
        ))}
      </ul>
    </div>
  )
}

function SearchHeading({ text }) {
  return <h3 className={styles.componentHeading}>{text}</h3>
}

function Suggestion({ item }) {
  const [_, setQueryString] = useQueryString()
  const { buttonId } = item

  return (
    <TagButton onClick={handleClick} label={`${item.label} (${item.count})`} dataX='click-to-select-suggested-search-tag' {...{ buttonId }} />
  )

  function handleClick() {
    setQueryString(x => ({ ...x, tags: [item.value] }))
  }
}

function SelectedSuggestion({ activeTags }) {
  const [_, setQueryString] = useQueryString()

  return (
    <ul className={styles.componentSelectedSuggestion}>
      {activeTags.map((tag, i) => (
        <TagButton key={i} buttonId={tag.buttonId} onClick={handleClick} label={tag.label} icon={markIcon} dataX='click-to-remove-suggested-search-tag' layoutClassName={styles.tagLayout} />
      ))}
    </ul>
  )

  function handleClick() {
    setQueryString(x => ({ ...x, tags: null }))
  }
}

function useDrawerStyle({ isOpen }) {
  const { size: { width }, ref: elementRef } = useElementSize()

  const [style, api] = useSpring(() => ({
    right: '-100%',
  }))

  React.useEffect(
    () => {
      api.start({
        right: isOpen ? '0px' : `-${width}px`
      })
    },
    [isOpen, api, width]
  )

  return { style, elementRef }
}

function useFloatingProps({ isOpen, onClose }) {
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: open => {
      if (!open) onClose()
    }
  })

  const dismiss = useDismiss(context, { capture: false })
  const { getFloatingProps } = useInteractions([dismiss])

  return { refs, getFloatingProps }
}

function useTags() {
  const language = useLanguage()
  const { bank, region, issue } = useSearchContext()

  const { data } = useQuery({
    queryKey: [language, bank, region, issue],
    queryFn: () => getEsEditionTags({ language, bank, issue, region }),
    initialData: {},
    refetchOnWindowFocus: false
  })

  const { tags = [] } = data

  return tags.map((x, buttonId) => ({ ...x, buttonId }))
}

function useArticles() {
  const language = useLanguage()
  const [search, setSearch] = React.useState('')
  const [{ tags: rawTags = [] }] = useQueryString()
  const { bank, region, issue } = useSearchContext()
  const debouncedSearch = useDebouncedValue(search)
  const tags = ensureArray(rawTags)

  const placeholderDataRef = React.useRef({ withinRegion: { articles: [], total: 0 }, notWithinRegion: { articles: [], total: 0 } })

  const { data, isError, isFetching } = useQuery({
    queryKey: [debouncedSearch, language, bank, region, issue, tags],
    queryFn: async () => {
      const data = await getSearchResult({ searchString: debouncedSearch, bank, region, issue, language, tags })
      placeholderDataRef.current = data
      return data
    },
    placeholderData: placeholderDataRef.current,
    refetchOnWindowFocus: false
  })

  const isFiltering = debouncedSearch || tags.length

  return {
    search: {
      value: search,
      setValue: setSearch
    },
    results: data,
    status: {
      isFiltering,
      isError,
      isFetching
    }
  }

  async function getSearchResult({ searchString, bank, region, issue, tags, language }) {
    if (!searchString && tags.length === 0) return { withinRegion: {}, notWithinRegion: {} }

    const { withinRegion, notWithinRegion }  = await getEsSearchResultWithinAndNotWithinRegion({ searchString, bank, region, issue, tags, language, size: 999 })

    pushToDataLayer({
      event: 'filter_changed',
      metadata: {
        filter: {
          ...(tags[0] !== null && { tag: tags[0] }),
          ...(searchString?.length && { searchterm: searchString }),
          maxresults: withinRegion.total
        }
      }
    })

    return { withinRegion, notWithinRegion }
  }
}
