import { useCardAnimations } from '/features/regionArticles/cards/useCardAnimations'
import { useItemUrl } from '/features/regionArticles/useItemUrl'
import { CardWithGradientBackgroundGreen, CardPlusButtonLink } from '/features/regionArticles/cards/Card'
import { CardImageWithoutShadow } from '/features/regionArticles/cards/CardImage'
import { HeadingGroupSnackables } from '/features/buildingBlocks/HeadingGroup'

import styles from './CardSnackables.css'

export function CardSnackables({ item, layoutClassName }) {
  const animations = useCardAnimations()

  const { content, metadata } = item
  const { hero, articles } = content
  const { rubric } = metadata

  const href = useItemUrl(articles[0])

  return (
    <CardWithGradientBackgroundGreen contentContainerClassName={styles.component} {...{ layoutClassName }}>
      <CardSnackablesContentBase
        title={hero.title}
        rubric={rubric.name}
        className={styles.contentLayout}
        {...{ articles, animations }}
      />
      <div className={styles.buttonContainer}>
        <CardPlusButtonLink layoutClassName={styles.plusButtonLayout} {...{ href }} />
      </div>
    </CardWithGradientBackgroundGreen>
  )
}

export function CardSnackablesContentIntro({ title, rubric, articles, animations = undefined, layoutClassName = undefined }) {
  return <CardSnackablesContentBase className={cx(styles.componentContentIntro, layoutClassName)} {...{ title, rubric, articles, animations }} />
}

function CardSnackablesContentBase({ title, rubric, articles, animations, className }) {
  return (
    <div className={cx(styles.componentContentBase, className)}>
      <Grid animation={animations?.imageScale} layoutClassName={styles.gridLayout} {...{ articles }} />
      <Header animation={animations?.headingGroup} layoutClassName={styles.headerLayout} {...{ title, rubric }} />
    </div>
  )
}

function Grid({ articles, animation, layoutClassName }) {
  return (
    <div
      className={cx(
        styles.componentGrid,
        styles[`gridLayoutWith${articles.length}Items`],
        layoutClassName
      )}
    >
      {articles.map((article, i) => (
        <CardImageWithoutShadow key={i} image={article?.content?.hero?.image} sizes='300px' {...{ animation }} />
      ))}
    </div>
  )
}

function Header({ title, rubric, animation, layoutClassName }) {
  return (
    <div className={cx(styles.componentHeader, layoutClassName)}>
      <div className={styles.headerContainer}>
        <HeadingGroupSnackables subtitle={rubric} {...{ title, animation }} />
      </div>
    </div>
  )
}
