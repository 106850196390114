import { animated, useSpring } from '@react-spring/web'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'

import { Image, ImageCover } from '/features/buildingBlocks/Image'

import styles from './ParallaxImage.css'

export function ParallaxImage({ image, distanceOverride = undefined, layoutClassName = undefined }) {
  return (
    <ParallaxImageBase className={layoutClassName} {...{ distanceOverride }}>
      <Image {...{ image }} />
    </ParallaxImageBase>
  )
}

export function ParallaxImageCover({ image, aspectRatio, distanceOverride = undefined, layoutClassName = undefined }) {
  return (
    <ParallaxImageBase className={layoutClassName} {...{ aspectRatio, distanceOverride }}>
      <ImageCover layoutClassName={styles.imageCoverLayout} {...{ image, aspectRatio }} />
    </ParallaxImageBase>
  )
}

function ParallaxImageBase({ children, distanceOverride, aspectRatio = undefined, className = undefined }) {
  const distance = distanceOverride ?? 150

  const [style, spring] = useSpring(() => ({
    y: 0,
    config: { tension: 500, friction: 35 }
  }))

  const elementRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.top() },
    onChange(progression) {
      spring.start({ y: lerp({ start: -distance, end: distance, input: progression }) })
    }
  })

  return (
    <div ref={elementRef} style={{ '--aspect-ratio': aspectRatio }} className={cx(styles.componentBase, className)}>
      <animated.div style={{ ...style, height: `calc(100% + ${distance * 1.5}px)`, inset: -distance + 'px 0' }} className={styles.imageContainer}>
        {children}
      </animated.div>
    </div>
  )
}
