import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { ImageCover } from '/features/buildingBlocks/Image'

import mediaStyles from '/cssGlobal/media.css'
import styles from './BreakpointImageCover.css'

export function BreakpointImageCover({ image, aspectRatioMobile, aspectRatioDesktop, sizes = undefined, imgProps = undefined, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  return (
    <>
      <div inert={!isViewportMd && 'true'} className={cx(styles.imageContainerMobile, layoutClassName)}>
        <ImageCover
          aspectRatio={aspectRatioMobile}
          layoutClassName={styles.imageLayout}
          {...{ image, sizes, imgProps }}
        />
      </div>
      <div inert={isViewportMd && 'true'}className={cx(styles.imageContainerDesktop, layoutClassName)}>
        <ImageCover
          aspectRatio={aspectRatioDesktop}
          layoutClassName={styles.imageLayout}
          {...{ image, sizes, imgProps }}
        />
      </div>
    </>
  )
}
